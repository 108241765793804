import React from 'react';
import IndexLayout from 'layouts';
import FeaturedSection from 'views/HomePage/FeaturedSection';
import TopSection404 from 'components/404/TopSection404';
import ContactSection from 'views/HomePage/ContactSection';
import { NotFound } from 'components';
import { Components } from 'sitedata';
import Helmet from 'react-helmet';

const NotFoundPage = (props) => {
  const showExpiredLink = props.location?.search?.search('expired');
  return (
    <IndexLayout>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Components.Header hasBorder />
      {showExpiredLink !== -1 ? <NotFound /> : (
        <>
          <TopSection404 />
          <FeaturedSection amountPropMob={8} />
          <ContactSection />
        </>
      )}
      <Components.Footer />
    </IndexLayout>
  );
};

export default NotFoundPage;
